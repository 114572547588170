jQuery(function($){
  
  //===============================================
  /* GSAPアニメーション定義 */
  //===============================================
  gsap.registerPlugin(ScrollTrigger);
  //gsap.registerPlugin(MotionPathPlugin);

  //===============================================
  /* GSAP：汎用フェードインアップ（スクロール駆動） */
  //===============================================

  gsap.set(".js-fadeinup", {opacity:0,y: 20}); //初期状態をセット

  ScrollTrigger.batch(".js-fadeinup", {
    onEnter: batch => gsap.to(batch, {opacity: 1, y: 0, stagger: {
      from: "start", //左側から
      amount: 0.4 // 0.8秒おきに
    }}),
    start: "top 80%",
    once: true,
    markers: false
  });

  gsap.set(".js-fadeindown", {opacity:0,y: -20}); //初期状態をセット

  ScrollTrigger.batch(".js-fadeindown", {
    onEnter: batch => gsap.to(batch, {opacity: 1, y: 0, stagger: {
      from: "start", //左側から
      amount: 0.4 // 0.8秒おきに
    }}),
    start: "top 55%",
    once: true,
    markers: false
  });

  //===============================================
  /* GSAP：ツバメの動き（スクロール駆動） */
  //===============================================
  gsap.set(".dogu01_left",{x: -200});
  gsap.set(".dogu01_right",{x: 200});
  gsap.set(".dogu02_left",{x: -200});
  gsap.set(".dogu02_right",{x: 200});
  gsap.set(".dogu03_left",{x: -200});
  gsap.set(".dogu03_right",{x: 200});
  gsap.set(".dogu04_left",{x: -200});
  gsap.set(".dogu04_right",{x: 200});

  ScrollTrigger.matchMedia({
    "all": function() {

      gsap.to(".dogu01_left", {
        repeat: 0,
        ease: Power4.easeOut,//Linearと同じ
        duration: 1.5,
        x:0,
        y:0,
        scrollTrigger: {
          trigger: ".dogu01_left",
          start: "top 90%",
          toggleActions: "play none none none",
        }
      });

      gsap.to(".dogu01_right", {
        repeat: 0,
        ease: Power4.easeOut,//Linearと同じ
        duration: 1.5,
        x:0,
        y:0,
        scrollTrigger: {
          trigger: ".dogu01_right",
          start: "top 80%",
          toggleActions: "play none none none",
        }
      });

      gsap.to(".dogu02_left", {
        repeat: 0,
        ease: Power4.easeOut,//Linearと同じ
        duration: 1.5,
        x:0,
        y:0,
        scrollTrigger: {
          trigger: ".dogu02_left",
          start: "top 90%",
          toggleActions: "play none none none",
        }
      });

      gsap.to(".dogu02_right", {
        repeat: 0,
        ease: Power4.easeOut,//Linearと同じ
        duration: 1.5,
        x:0,
        y:0,
        scrollTrigger: {
          trigger: ".dogu02_right",
          start: "top 80%",
          toggleActions: "play none none none",
        }
      });

      gsap.to(".dogu03_left", {
        repeat: 0,
        ease: Power4.easeOut,//Linearと同じ
        duration: 1.5,
        x:0,
        y:0,
        scrollTrigger: {
          trigger: ".dogu03_left",
          start: "top 90%",
          toggleActions: "play none none none",
        }
      });

      gsap.to(".dogu03_right", {
        repeat: 0,
        ease: Power4.easeOut,//Linearと同じ
        duration: 1.5,
        x:0,
        y:0,
        scrollTrigger: {
          trigger: ".dogu03_right",
          start: "top 80%",
          toggleActions: "play none none none",
        }
      });

      gsap.to(".dogu04_left", {
        repeat: 0,
        ease: Power4.easeOut,//Linearと同じ
        duration: 1.5,
        x:0,
        y:0,
        scrollTrigger: {
          trigger: ".dogu04_left",
          start: "top 90%",
          toggleActions: "play none none none",
        }
      });

      gsap.to(".dogu04_right", {
        repeat: 0,
        ease: Power4.easeOut,//Linearと同じ
        duration: 1.5,
        x:0,
        y:0,
        scrollTrigger: {
          trigger: ".dogu04_right",
          start: "top 80%",
          toggleActions: "play none none none",
        }
      });
    }
  });

  $(window).on('resize', function(){
    ScrollTrigger.refresh();
  });
});